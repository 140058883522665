.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__close-icon::after {
  background-color: #0c1230;
}

.react-datepicker-popper {
  z-index: 3;
}